<template>
  <div :data-node="true" class="mt-fix-v">
    <a
      @click.prevent="show"
      class="btn btn-default-grad-purple-fill white-font blue-btn-custom"
      :data-node="true"
      >Configurar</a
    >
    <modal
      :name="'dialogConfig' + id"
      transition="fade"
      :min-width="200"
      :min-height="150"
      :scrollable="true"
      height="auto"
      :width="setProps"
      classes="border-none"
      @closed="hide()"
      style="z-index: 1001 !important"
    >
      <div class="modal-content" style="background: transparent !important">
        <div class="modal-header" style="position: absolute; z-index: 1001; border: none; width: 100%">
          <button @click="hide"
            alt="quitar configuracion" aria-label="quitar configuracion"
            type="button" class="bootbox-close-button close button-close-new">×</button>
        </div>
        <div :class="{ 'padding-custom-dialog-v': campos.length > 2 }">
          <configuration-component
            :campos="campos"
            :id="id"
            :type="2"
            :service="service"
            @addAdicional="hide"
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import ConfigurationComponent from "./ConfigurationComponent";
export default {
  components: {
    ConfigurationComponent,
  },
  props: ["id", "campos", "service"],
  data: () => ({}),
  computed: {
    setProps() {
      if (window.innerWidth < 900) {
        return "95%";
      } else {
        return "680px";
      }
    },
  },
  methods: {
    show() {
      this.$modal.show("dialogConfig" + this.id);
      this.$emit("show");
    },
    hide() {
      this.$modal.hide("dialogConfig" + this.id);
      this.$emit("hide");
    },
  },
};
</script>

<style>
@media (max-width: 991px) {
  .padding-custom-dialog-v {
    padding: 30px 20px 100px 20px !important;
  }
}

.button-close-new {
  background: no-repeat;
  color: black !important;
}
.font-header {
  font-family: "Trebuchet MS", sans-serif;
  font-size: 24px;
  letter-spacing: -2px;
  border-bottom: 2px solid black;
  text-transform: uppercase;
}

.border-none {
  border-radius: 4px !important;
  width: 680px;
  z-index: 1001 !important;
}

@media (max-width: 1000px) {
  .border-none {
    width: 95%;
    min-width: auto;
  }
}
</style>
